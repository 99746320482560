import store from "@/store";
import reportStoreModule from "../reportStoreModule";
import toast from "@/utils/toast";
import { onUnmounted, ref, watch } from "@vue/composition-api";
import moment from "moment";

export default function useReportByServiceType() {

  const STORE_MODULE_NAME = 'report';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, reportStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  const reportType = ref({ name: "Khoản thu theo lớp và loại dịch vụ", id: 1 })
  const school = ref(null)
  const month = ref(moment().startOf('month').format("MM-YYYY"))
  const isLoading = ref(false)
  const htmlContent = ref(null)

  const fetchData = (isExport) => {
    isLoading.value = true
    let dispatchName = 'getReportByServiceType'
    if (reportType.value.id === 2) {
      dispatchName = 'getReportByService'
    } else if (reportType.value.id === 3) {
      dispatchName = 'getReportRevenueSummary'
    }
    store.dispatch(`report/${dispatchName}`, { month: month.value, schoolId: school.value ? school.value.id : null, isExport }).then(response => {
      isLoading.value = false
      if (isExport) {
        window.location.href = response.data;
      } else {
        htmlContent.value = response.data
      }

    }).catch(error => {
      toastification.showToastError(error)
      isLoading.value = false
    })
  }

  const exportData = () => {
    fetchData(true)
  }

  watch(month, newVal => {
    fetchData()
  })
  watch(school, newVal => {
    fetchData()
  })
  watch(reportType, newVal => {
    fetchData()
  })

  return { school, month, fetchData, htmlContent, reportType, exportData }
}